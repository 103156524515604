<template>
  <div></div>
</template>

<script>
export default {
  name: 'PDFRedirect',
  components: {
  },
  watch: {
    '$route.query.pdf_url': {
      handler: function (newVal) { 
        if (newVal) {
          window.location.replace(newVal)
        }
      },
      immediate: true
    }
  },
}
</script>
